<template>
  <div class="card card-custom py-0">
    <div class="card-body card-body-scroll py-0 pb-2">
      <div class="bg-white">
        <div class="d-flex flex-column items-center justify-content-center w-100">
          <div class="d-block align-content-center">
            <p class="text-center mb-0">
            <span v-html="getIconByKey('icons.sample.meeting-participant', {
                        class: 'w-75px h-75px object-cover'
                  })">
            </span>
            </p>
          </div>
          <h4 class="text-center w-100 text-primary font-weight-boldest"
              style="text-align: center; margin-top: -10px;">
            {{ $t("general.please_select_someone_who_is_responsible_for_selected_products") }}
          </h4>
          <br>
        </div>
        <div @click="modelProxy = user.id" :key="index"
             class="d-flex align-items-top justify-content-between cursor-pointer py-1 my-0" v-for="(user, index) in users"
             style="border-bottom: 1px solid rgba(238,238,238,0.6)">
          <div class="d-flex items-baseline align-items-center justify-content-start cursor-pointer " style="align-items: center;">
            <span>
              <img
                  :src="user.avatar_file"
                  class="rounded-full w-35px h-35px object-fill bg-gray-200 user-image"
                  style="border: 1px solid #d4d4d4;"
                  alt="">
            </span>
            <h6 class="mx-4">{{ user.name }}</h6>
          </div>
          <div class="table-vertical-center d-flex mt-2">
            <label class="radio radio-primary d-inline-block">
              <input type="radio" name="user" :value="user.id" v-model="modelProxy">
              <span></span>
            </label>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SelectSampleUserModal",
  props: {
    model: {
      required: true,
    },
    users: {
      required: true,
      type: Array,
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.radio.radio-primary > input:checked ~ span::after {
  background-color: $primary;
}
.user-image {
  border-radius: 50%;
}
</style>
